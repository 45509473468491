import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { signInRedirect } from '../../services/auth.service'
import roleConstants from '../../constants/roles.constants'
import tokenConstants from '../../constants/token.constants'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { getUser } from '../../services/auth.service'
import { serviceAccessCheck } from '../../actions/auth.actions'
import { useSelector, useDispatch } from 'react-redux'
import LoadingPage from '../loadingPage'

function ProtectedRoute({ requiredRoles, component: Component, ...rest }) {
  const location = useLocation()
  const dispatch = useDispatch()

  const { serviceHasAccess, isCheckingServiceAccess } = useSelector(
    state => state.auth
  )

  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [hasRoles, setHasRoles] = useState(false)

  useEffect(() => {
    async function initialiseAccessData() {
      // get user first
      let user
      try {
        user = await getUser()
      } catch (error) {
        console.error(error)
      }

      if (!user) {
        setIsLoading(false)
        return
      }

      // API call to check service access if we don't know it
      if (serviceHasAccess === null) {
        dispatch(serviceAccessCheck())
      }

      // check required roles against user roles
      let userHasRoles = false
      const rolesRequired = requiredRoles && requiredRoles.length
      if (rolesRequired) {
        if (
          typeof user.profile[tokenConstants.ROLES] === 'string' &&
          requiredRoles.length === 1
        ) {
          // user roles is a string if only 1 role is present
          userHasRoles = user.profile[tokenConstants.ROLES] === requiredRoles[0]
        } else if (Array.isArray(user.profile[tokenConstants.ROLES])) {
          // if roles is an array, check every required role is included in user roles
          userHasRoles = requiredRoles.every(role =>
            user.profile[tokenConstants.ROLES].includes(role)
          )
        }
      }

      setIsAuthenticated(true)
      setHasRoles(rolesRequired ? userHasRoles : true)
      setIsLoading(false)
    }
    initialiseAccessData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const roleGuardedRoute = props => {
    if (!isAuthenticated) {
      return (
        <Redirect
          to={signInRedirect({
            state: {
              postLoginRedirect:
                window.location.pathname + window.location.search,
            },
          })}
        />
      )
    }

    if (!serviceHasAccess) return <Redirect to='/access' />

    if (!hasRoles)
      return (
        <Redirect
          to={{
            pathname: '/permissions',
            state: { from: location.pathname },
          }}
        />
      )

    // if all above checks pass, return the protected component
    return <Component {...props} />
  }

  return isLoading || isCheckingServiceAccess ? (
    <LoadingPage text='Checking permissions' />
  ) : (
    <Route {...rest} render={props => roleGuardedRoute(props)} />
  )
}

ProtectedRoute.propTypes = {
  requiredRoles: PropTypes.arrayOf(
    PropTypes.oneOf([
      roleConstants.ADMIN,
      roleConstants.BUSINESS,
      roleConstants.HR,
      roleConstants.KNOWLEDGE_PORTAL,
      roleConstants.MI,
    ])
  ),
  component: PropTypes.func.isRequired,
}

export default ProtectedRoute
