import { UserManager, Log } from 'oidc-client'
import httpClient from '../utils/axios'
import { handleServiceError } from '../utils/serviceResponseHelper'
import appServiceConstants from '../constants/appservice.constants'
import { storeUser } from '../actions/auth.actions'
import store from '../store'

const config = {
  authority: window.config.IDENTITY_URL,
  client_id: window.config.OIDC_CLIENT_ID,
  redirect_uri: window.config.IDENTITY_REDIRECT_URI,
  response_type: 'id_token token',
  scope:
    'openid profile processenginedocumentserviceapiaccess processengineserviceapiaccess',
  post_logout_redirect_uri: window.config.IDENTITY_POST_LOGOUT_URI,
  silent_redirect_uri: window.config.IDENTITY_SILENT_RENEW_URI,
  loadUserInfo: true,
  includeIdTokenInSilentRenew: false,
}

const userManager = new UserManager(config)

if (process.env.NODE_ENV !== 'production') Log.logger = console

export async function getAccessToken() {
  try {
    const user = await userManager.getUser()
    if (!user || user.expired) {
      return null
    }
    return `Bearer ${user.access_token}`
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function checkServiceAccess() {
  return httpClient.get(`${window.config.SERVICE_API_URL}/v1/app`).then(
    response => {
      const { data: allowedAppsList } = response
      return allowedAppsList.some(
        app => app.id === appServiceConstants.DOC_SERVICE_APP
      )
    },
    error => handleServiceError(error)
  )
}

export async function getUser() {
  try {
    const user = await userManager.getUser()
    if (!user || user.expired) {
      return null
    }
    store.dispatch(storeUser(user))
    return user
  } catch (error) {
    return Promise.reject(error)
  }
}

export function signInRedirect(preRedirectState) {
  return userManager.signinRedirect(preRedirectState)
}

export function signInRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signInSilent() {
  return userManager.signinSilent()
}

export function signInSilentCallback() {
  return userManager.signinSilentCallback()
}

export function signOutRedirect() {
  return userManager.signoutRedirect()
}

export async function signOutRedirectCallback() {
  await userManager.clearStaleState()
  await userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager
