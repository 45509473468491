import React, { useEffect } from 'react'
import { signInRedirectCallback } from '../services/auth.service'
import { useHistory } from 'react-router-dom'
import LoadingPage from '../components/loadingPage'

function SignInCallback() {
  const history = useHistory()

  useEffect(() => {
    signInRedirectCallback().then(user => {
      history.push(user.state?.postLoginRedirect || '/')
    })
  }, [history])

  return <LoadingPage text='Signing in' />
}

export default SignInCallback
